/* variables.css */

:root {
  /* --yellowBtn: #FACD49; */
  --yellowBtn: #FFD700;
  --blueThemColour:#2639ED
}
/* ---------------- */
body{
  font-family: Averta-Demo-font;
}
.offcanvas {
  max-width: 90% !important
}
.text-content{
  color: #19182580;
  text-align: justify;
}
.imgsetallperson{
  margin-bottom: -3%;
}

.pinkimg-join-right{
  width: 130px;

}
.pink-set-img-right{

  position: absolute;
  top: 0px;
  z-index: -1;
  right:0px;
}
.bgskyfly{
  position: absolute;
  top: 84%;
}
.footer-data-size a{
  text-decoration: none !important;
  color: black !important;
}
.marketingDotscommanimg{
  width: 150px;
  position: relative;
  z-index: -222;
}
.marketingDotscommandiv{
  position: absolute;
  right: 0;
}
.newhomecircle-round-div-set .slick-dots{
  bottom: 57px !important;
  left: -104px !important;
}
.newhomecircle-round-div-set .sliderpre-next-divset{
  display: none;
}
.spiner-set{
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.spinner-border {
  --bs-spinner-width: 4rem !important;
  --bs-spinner-height: 4rem !important;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("./images/allcommonimg/uparrow.png") !important;
  transform:rotate(0deg) !important;
  padding: 15px;
  border-radius: 50px;
  background-color: var(--blueThemColour);
  display: flex;
  justify-content: center;
  align-items: center;
  background-size:contain
}
.accordion-button::after{
  background-image:url("./images/allcommonimg/uparrowblue.png") !important;
  transform:rotate(90deg) !important;
}
@font-face {
  font-family: 'Averta';
  src: url('./Font/FontsFree-Net-AvertaDemoPECuttedDemo-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
h1 b {
  font-family: 'Averta', Arial;
}
h3{
  font-family: 'Averta', Arial;

}

.Averta-Demo-font {
  font-family: 'Averta', Arial;
}
.setnewsliderzindexadd{
  z-index: 200;
  position: relative;
}
@media (min-width: 0px) and (max-width: 350px) {
  .bgskyfly{
    top: 139%;
  }
  }
  @media (min-width: 351px) and (max-width: 375px) {
  .bgskyfly{
    top: 176%;
  }
  }
  @media (min-width: 376px) and (max-width: 425px) {
    .bgskyfly{
      top: 161%;
    }
  }
  @media (min-width: 426px) and (max-width: 576px) {
    .bgskyfly{
      top: 143%;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .bgskyfly{
      top: 150%;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .bgskyfly{
      top: 98%;
    }
  }
  @media (min-width: 992px) and (max-width: 1023px) {
    .bgskyfly{
      top: 120%;
    }
  }
  @media (min-width: 1024px) and (max-width: 1300px) {
    .bgskyfly{
      top: 115%;
    }
  }
  @media (min-width: 1301px) and (max-width: 1366px) {
    .bgskyfly{
      top: 150%;
    }
  }
  @media (min-width: 1367px) and (max-width: 1439px) {
    .bgskyfly{
      top: 110%;
    }
  }
  @media (min-width: 1440px) and (max-width: 1499px) {
    .bgskyfly{
      top: 126%;
    }
  }
  @media (min-width: 1500px) and (max-width: 1550px) {
    .bgskyfly{
      top: 134%;
    }
  }

  @media (min-width: 2000px) and (max-width: 3000px) {
    .bgskyfly {
      position: absolute;
      top: 62%;
  }
  }
  .allhedersection-font{
    font-weight: 500;
  }
  /* first all section fontsize */

  @media (min-width: 1200px) and (max-width: 1499px) {
    .allhedersection-font{
      font-size: 3.5rem !important;
    }
    .home-calltoaction{
      font-size: 24px;
    }
  }

  @media (min-width: 1500px) {
    .allhedersection-font{
      font-size: 4.5rem !important;
    }
    .home-calltoaction{
      font-size: 28px;
    }
  }

  
  @media (min-width: 1200px) and (max-width: 1499px) {
    .allhedersection-font{
      font-size: 3.5rem !important;
    }
    .home-calltoaction{
      font-size: 24px;
    }
  }


  
  /* all circle round releated css */

  @media (min-width: 0px) and (max-width: 991px) {
    .newhomecircle-round-div-set .slick-dots {
      bottom: -32px !important;
      left: 0% !important;
  }
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    .newhomecircle-round-div-set .slick-dots {
      bottom: 0px !important;
      left: -7% !important;
  }
}
@media (min-width: 1200px) and (max-width: 1400px) {
  .newhomecircle-round-div-set .slick-dots {
    bottom: 30px !important;
    left: -9% !important;
}
}

@media (min-width: 992px) and (max-width: 1199px) {
    .setcircle-roundset-col{
      position: absolute;
      top: 35%;
    }
  }

  
  @media (min-width: 1200px) {
    .setcircle-roundset-col{
      position: absolute;
      right: 10%;
      top: 35%;
    }
  }

  @media (max-width: 991px) {
    .setcircle-roundset-col{
    margin: 100px auto 170px;
    padding-right: 40px;
    }

  }